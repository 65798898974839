import { useState, useEffect } from "react";

export const useContractAbi = (provider, config, chainId) => {
  const [abis, setAbis] = useState();

  useEffect(() => {
    const getAbis = async () => {
      if (provider && config) {
        const currentChainId = chainId;

        const contractList = { ...(config.deployedContracts ?? {}) };
        // const externalContractList = {
        //   ...(config.externalContracts ?? {}),
        // };
        let combinedContracts = {};

        // combine partitioned contracts based on all the available and chain id.
        if (contractList?.[currentChainId] != null) {
          for (const oneNetwork in contractList[currentChainId]) {
            if (
              Object.prototype.hasOwnProperty.call(
                contractList[currentChainId],
                oneNetwork
              )
            ) {
              if (
                !config.hardhatNetworkName ||
                oneNetwork === config.hardhatNetworkName
              ) {
                combinedContracts = {
                  ...combinedContracts,
                  ...contractList?.[currentChainId]?.[oneNetwork]?.contracts,
                };
              }
            }
          }
        }
        setAbis(combinedContracts);
      }
    };
    getAbis();
  }, [provider, config, chainId]);

  return abis;
};
