import { useState, useEffect } from "react";

export const useNetwork = (provider) => {
  const [network, setNetwork] = useState(undefined);

  useEffect(() => {
    const getNetwork = async () => {
      if (!!provider) {
        let network = await provider.getNetwork();
        setNetwork(network);
      }
    };
    getNetwork();
  }, [provider]);

  return network;
};
