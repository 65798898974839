import React from "react";
import { map } from "lodash";
import { SingleSplitter } from "./SingleSplitter";
import * as styles from "../App.module.scss";

export default function ExistingSplitters(props) {
  const {
    address,
    existingSplitters,
    provider,
    readContracts,
    contractAbis,
    signer,
    title,
  } = props;
  const existingSplitterList = map(existingSplitters, (es) => {
    return (
      <SingleSplitter
        key={es}
        userAddress={address}
        address={es}
        readContracts={readContracts}
        provider={provider}
        contractAbis={contractAbis}
        signer={signer}
      />
    );
  });

  return (
    <div>
      <h3 className={styles.existingSplittersTitle}>{title}</h3>
      <div>{existingSplitterList}</div>
    </div>
  );
}
