import React, { useEffect, useState } from "react";
// import "./App.module.scss";
import { ethers } from "ethers";
import { has } from "lodash";
import Web3Info from "./components/Web3Info";
import ExistingSplitters from "./components/ExistingSplitters";
import CreateSplitter from "./components/CreateSplitter";
import Footer from "./components/Footer";
import { useContractConfig } from "./hooks/useContractConfig";
import { useNetwork } from "./hooks/useNetwork";
import { useSigner } from "./hooks/useSigner";
import { useContractAbi } from "./hooks/useContractAbi";
import { useContractLoader } from "eth-hooks";
import * as styles from "./App.module.scss";
import { useExistingSplitters } from "./hooks/useExistingSplitters";
import { useAddress } from "./hooks/useAddress";
import { useCreatedSplitters } from "./hooks/useCreatedSplitters";
import NetworkSelector from "./components/NetworkSelector";

const etherscanUrlsByNetwork = {
  localhost: "",
  ropsten:
    "https://ropsten.etherscan.io/address/0x613b182cb560380ffec2ff903c8155f8502bd006",
  rinkeby:
    "https://rinkeby.etherscan.io/address/0x613b182cb560380FfeC2Ff903C8155f8502Bd006",
  mainnet:
    "https://etherscan.io/address/0x965D0952ad0DaA235Eb0dba933A061d288EDae9a",
  matic:
    "https://polygonscan.com/address/0x0dBaA5F3E9Cc4f5D56436ec6CC9e5ad6D7C91f5B",
};

const useProvider = (wndEthereum) => {
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (wndEthereum !== undefined) {
      setProvider(new ethers.providers.Web3Provider(wndEthereum));
      // add mew listeners?
      // if (provider !== null) {
      //   provider.on("connect", (connectInfo) => {
      //     console.log("connected");
      //   });
      //   provider.on("disconnect", (error) => {
      //     console.log("disconnect");
      //   });
      // }
    }
  }, [wndEthereum]);

  return provider;
};

export function AppProvider(props) {
  const provider = useProvider(window.ethereum);
  return <App provider={provider}></App>;
}

export function App(props) {
  const { provider } = props;
  const network = useNetwork(provider);

  const contractConfig = useContractConfig(provider);
  console.log("contractConfig", contractConfig);
  const [signer, updateSigner] = useSigner(provider);
  const address = useAddress(signer);

  const [chainId, setChainId] = useState();
  useEffect(() => {
    if (network !== undefined) {
      setChainId(network.chainId);
    }
  }, [network]);

  const readContracts = useContractLoader(provider, contractConfig, chainId);
  const writeContracts = useContractLoader(signer, contractConfig, chainId);
  const contractAbis = useContractAbi(provider, contractConfig, chainId);

  // existing splitters state and updating
  const [existingSplitters] = useExistingSplitters(
    provider,
    readContracts,
    address
  );
  const [createdSplitters, updateCreatedSplitters] = useCreatedSplitters(
    provider,
    readContracts,
    address
  );

  let warning = null;
  if (!has(readContracts, "PaymentSplitterManagerClones")) {
    warning = (
      <div id="warning" className={styles.warning}>
        Payment Splitter contract not loaded; you may be on the wrong network.
      </div>
    );
  }

  return (
    <div className="App">
      <div>
        <div className={styles.header}>
          <div className={styles.header_half}>
            <h1>PaymentSplitter.io</h1>
            <p className={styles.header_description}>
              A smart contract to spawn payment splitter smart contracts on the
              Ethereum network.{" "}
              <a href={etherscanUrlsByNetwork[process.env.ETH_NETWORK_NAME]}>
                Verified on EtherScan
              </a>
            </p>
            <p className={styles.header_description}>
              <a href="https://medium.com/northwest-nfts/announcing-paymentsplitter-io-9b27eccfacd4">
                Learn more about PaymentSplitter.io
              </a>
            </p>
          </div>
          <Web3Info
            provider={provider}
            signer={signer}
            updateSigner={updateSigner}
            className={styles.header_half}
          />
          <NetworkSelector />
        </div>
        {warning}
        <CreateSplitter
          provider={provider}
          signer={signer}
          readContracts={writeContracts}
          writeContracts={writeContracts}
          updateCreatedSplitters={updateCreatedSplitters}
        />
        <ExistingSplitters
          title={"Splitters created by you:"}
          address={address}
          provider={provider}
          readContracts={writeContracts}
          contractAbis={contractAbis}
          existingSplitters={createdSplitters}
          signer={signer}
        />
        <ExistingSplitters
          title={"Splitters you're a payee of:"}
          address={address}
          provider={provider}
          readContracts={writeContracts}
          contractAbis={contractAbis}
          existingSplitters={existingSplitters}
          signer={signer}
        />
        <Footer />
      </div>
    </div>
  );
}
//
export default App;
