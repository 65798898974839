import { forEach, findIndex, get, has } from "lodash";

const kDuplicateAddressErrorMessage =
  "Error: VM Exception while processing transaction: reverted with reason string 'PaymentSplitter: account already has shares'";
const kSharesAreZeroErrorMessage =
  "Error: VM Exception while processing transaction: reverted with reason string 'PaymentSplitter: shares are 0'";

export const identifyErrorSource = (
  error,
  addresses,
  shares,
  setValidationErrors
) => {
  if (has(error, "code")) {
    if (
      error.code === "INVALID_ARGUMENT" &&
      get(error, "argument") === "name"
    ) {
      // invalid address
      const value = error.value;
      const index = findIndex(addresses, (addr) => {
        return value === addr;
      });
      const newValidationErrors = new Array(addresses.length).fill(null);
      newValidationErrors[index] = "Invalid address";
      setValidationErrors(newValidationErrors);
    } else if (
      error.code === "INVALID_ARGUMENT" &&
      get(error, "argument") === "value"
    ) {
      // invalid shares
      const value = error.value;
      const index = findIndex(shares, (addr) => {
        return value === addr;
      });
      const newValidationErrors = new Array(shares.length).fill(null);
      newValidationErrors[index] = "Invalid shares";
      setValidationErrors(newValidationErrors);
    } else if (
      error.code === "INVALID_ARGUMENT" &&
      get(error, "argument") === null &&
      get(error, "reason") === "value out-of-bounds"
    ) {
      // invalid shares
      const value = error.value;
      const index = findIndex(shares, (addr) => {
        return value === addr;
      });
      const newValidationErrors = new Array(shares.length).fill(null);
      newValidationErrors[index] = "Invalid shares";
      setValidationErrors(newValidationErrors);

      // reason: 'value out-of-bounds',
    } else if (error.code === -32603) {
      const msg = get(error, "data.message", "");
      if (msg === kDuplicateAddressErrorMessage) {
        // go find a duplicate address
        const foundAddresses = {};
        // for (const addr in addresses) {
        forEach(addresses, (addr, index) => {
          // check if addr is in hash:
          // if so, we found duplicate
          // else, add addr to hash
          if (has(foundAddresses, addr)) {
            // this one
            const newValidationErrors = new Array(addresses.length).fill(null);
            newValidationErrors[index] = "Duplicate address";
            setValidationErrors(newValidationErrors);
          } else {
            foundAddresses[addr] = true;
          }
        });
      } else if (msg === kSharesAreZeroErrorMessage) {
        // find first share that is zero

        const index = findIndex(shares, (share) => {
          return share === 0;
        });
        const newValidationErrors = new Array(addresses.length).fill(null);
        newValidationErrors[index] = "Invalid shares";
        setValidationErrors(newValidationErrors);
      }
    }
  }
};
