import { useEffect, useState } from "react";
import { has } from "lodash";

export const useExistingSplitters = (provider, readContracts, address) => {
  const [existingSplitters, setExistingSplitters] = useState();
  const getCloneableInfo = async () => {
    if (
      has(readContracts, "PaymentSplitterManagerClones") &&
      address !== undefined
    ) {
      const ps =
        await readContracts.PaymentSplitterManagerClones.registeredSplittersOf(
          address
        ).catch((e) => {
          console.log("error", e);
        });
      setExistingSplitters(ps);
    }
  };
  const updateExistingSplitters = () => {
    getCloneableInfo();
  };

  // eslint-disable-next-line
  useEffect(updateExistingSplitters, [provider, readContracts, address]);

  return [existingSplitters, updateExistingSplitters];
};
