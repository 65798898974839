import React, { useEffect, useState } from "react";
import { findIndex, map } from "lodash";
import { ethers } from "ethers";

import * as styles from "../App.module.scss";

export const SingleSplitter = (props) => {
  //
  const {
    address,
    userAddress,
    contractAbis,
    readContracts,
    provider,
    signer,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const [payees, setPayees] = useState([]);
  const [shares, setShares] = useState([]);
  const [balance, setBalance] = useState(null);
  const [balances, setBalances] = useState([]);

  useEffect(() => {
    // payees, shares, balance
    if (
      readContracts &&
      readContracts.PaymentSplitterManagerClones &&
      contractAbis.PaymentSplitterManagerClones
    ) {
      provider.getBalance(address).then((bal) => {
        setBalance(ethers.utils.formatEther(bal));
      });

      readContracts.PaymentSplitterManagerClones.payees(address).then((p) => {
        setPayees(p);
      });
      readContracts.PaymentSplitterManagerClones.shares(address).then((ss) => {
        const shares = map(ss, (s) => {
          return s.toNumber();
        });
        setShares(shares);
      });
      readContracts.PaymentSplitterManagerClones.balances(address).then(
        (bals) => {
          const balsNumbers = map(bals, (bn) => {
            return ethers.utils.formatEther(bn);
          });
          setBalances(balsNumbers);
        }
      );
    }
  }, [provider, address, readContracts, contractAbis]);

  const releaseFunds = (releaseIndex) => {
    // release funds for user
    const payee = payees[releaseIndex];
    const cloneableInstance = new ethers.Contract(
      address,
      contractAbis.PaymentSplitterCloneable.abi,
      signer
    );
    cloneableInstance
      .release(payee)
      .then(() => {})
      .catch((error) => {
        console.error("error", error);
      });
  };

  const userIndex = findIndex(payees, (payee) => {
    return payee === userAddress;
  });
  const showUserRow = !expanded && userIndex !== -1;
  let payeeRows = null;
  if (expanded) {
    payeeRows = map(payees, (payee, i) => {
      let payeeContent = payee;
      if (payee === userAddress) {
        payeeContent = <strong>{payee}</strong>;
      }
      const share = shares[i];
      const bal = balances[i];
      return (
        <div className={styles.splitterRow} key={i}>
          <p className={styles.textOnly}>{payeeContent}</p>
          <p className={styles.textOnly}>{share}</p>
          <p className={styles.textOnly}>
            {bal}
            {ethers.constants.EtherSymbol}
          </p>
          <p>
            <button
              onClick={() => {
                releaseFunds(i);
              }}
              className={styles.releaseFunds}
            >
              Release funds
            </button>
          </p>
        </div>
      );
    });
  } else if (showUserRow) {
    const payeeContent = <strong>{payees[userIndex]}</strong>;
    const share = shares[userIndex];
    const bal = balances[userIndex];

    payeeRows = (
      <div className={styles.splitterRow} key={userIndex}>
        <p className={styles.textOnly}>{payeeContent}</p>
        <p className={styles.textOnly}>{share}</p>
        <p className={styles.textOnly}>
          {bal}
          {ethers.constants.EtherSymbol}
        </p>
        <p>
          <button
            onClick={() => {
              releaseFunds(userIndex);
            }}
            className={styles.releaseFunds}
          >
            Release funds
          </button>
        </p>
      </div>
    );
  }

  return (
    <div className={styles.singleSplitterWrap}>
      <div className={styles.singleSplitterHeader}>
        <div>
          <p key={address} className={styles.contractAddress}>
            Contract Address: <span>{address}</span>
          </p>
          <p>
            Contract Total Balance:{" "}
            <span>
              {balance}
              {ethers.constants.EtherSymbol}
            </span>
          </p>
        </div>
        <div className={styles.endOfGrid}>
          <button
            onClick={() => {
              setExpanded(!expanded);
            }}
            className={styles.expandButton}
          >
            {expanded ? "Collapse" : "Expand"}
          </button>
        </div>
      </div>
      <div>
        {expanded || showUserRow ? (
          <div key="rowheader" className={styles.splitterRowHeader}>
            <p>Payee:</p>
            <p>Shares:</p>
            <p>Balance:</p>
            <p></p>
          </div>
        ) : null}
        {payeeRows}
      </div>
    </div>
  );
};
