import React, { useEffect, useState } from "react";
import { map, sum } from "lodash";
import { useAddress } from "../hooks/useAddress";
import * as styles from "../App.module.scss";
import { identifyErrorSource } from "../utils/identifyErrorSource";
import { ethers } from "ethers";

export default function CreateSplitter(props) {
  const { signer, updateCreatedSplitters, readContracts, writeContracts } =
    props;
  const [sectionOpen, setSectionOpen] = useState(false);
  const [created, setCreated] = useState(false);
  const [tax, setTax] = useState(null);
  const signerAddress = useAddress(signer);

  const [addresses, setAddresses] = useState(["", ""]);
  const [shares, setShares] = useState([1, 1]);
  const [validationErrors, setValidationErrors] = useState([null, null]);

  useEffect(() => {
    if (readContracts && readContracts.PaymentSplitterManagerClones) {
      readContracts.PaymentSplitterManagerClones.tax()
        .then((t) => {
          setTax(t);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [readContracts]);

  const addAddress = () => {
    setAddresses([...addresses, ""]);
    setShares([...shares, 1]);
    setValidationErrors([...validationErrors, null]);
  };

  const removeAddress = () => {
    setAddresses([...addresses.slice(0, addresses.length - 1)]);
    setShares([...shares.slice(0, shares.length - 1)]);
    setValidationErrors([
      ...validationErrors.slice(0, validationErrors.length - 1),
    ]);
  };

  const handleAddressChange = (event, fieldId) => {
    addresses[fieldId] = event.target.value;
    setAddresses([...addresses]);
    if (validationErrors[fieldId] !== null) {
      setValidationErrors(new Array(addresses.length).fill(null));
    }
  };

  const handleSharesChange = (event, fieldId) => {
    const newShares = parseInt(event.target.value, 10);
    shares[fieldId] = isNaN(newShares) ? "" : newShares;
    setShares([...shares]);
    if (validationErrors[fieldId] !== null) {
      setValidationErrors(new Array(addresses.length).fill(null));
    }
  };

  const createSplitter = (event) => {
    event.preventDefault();

    if (
      writeContracts.PaymentSplitterManagerClones !== undefined &&
      tax !== null
    ) {
      writeContracts.PaymentSplitterManagerClones.newSplitter(
        addresses,
        shares,
        { value: tax }
      )
        .then(() => {
          setCreated(true);
          updateCreatedSplitters();
        })
        .catch((error) => {
          console.error(error);
          identifyErrorSource(error, addresses, shares, setValidationErrors);
        });
    }
  };

  const taxLabel =
    tax !== null ? (
      <span className={styles.taxLabel}>
        This transactional will cost {ethers.utils.formatEther(tax)}
        {ethers.constants.EtherSymbol} + gas
      </span>
    ) : null;

  const totalShares = sum(shares);
  const addressContents = !sectionOpen ? null : (
    <>
      {map(addresses, (address, i) => {
        const share = shares[i];
        const percent = ((100 * share) / totalShares).toLocaleString(
          "fullwide",
          { maximumFractionDigits: 3 }
        );
        let addButton = null;
        if (i === addresses.length - 1 && !created) {
          addButton = (
            <>
              <button onClick={addAddress} className={styles.addAddress}>
                + Add Address
              </button>
              <button onClick={removeAddress} className={styles.removeAddress}>
                - Remove Address
              </button>
            </>
          );
        }
        const validationErr = validationErrors[i];
        const formValidationError =
          validationErr === null ? null : (
            <div className={styles.form_warning_wrapper}>
              <span className={styles.form_warning}>{validationErr}</span>
            </div>
          );

        return (
          <div key={`addrbox_${i}`} className={styles.addrbox}>
            {formValidationError}
            <label htmlFor={`addr_${i}`}>Address</label>
            <input
              name={`addr_${i}`}
              type="text"
              value={address}
              className={styles.addressInput}
              onChange={(event) => handleAddressChange(event, i)}
              disabled={created}
            ></input>
            <label htmlFor={`share_${i}`} className={styles.shareLabel}>
              Shares:
            </label>
            <input
              name={`share_${i}`}
              type="number"
              value={share}
              className={styles.sharesInput}
              onChange={(event) => handleSharesChange(event, i)}
              disabled={created}
            ></input>
            <label htmlFor={`percent_${i}`} className={styles.shareLabel}>
              Percentage: {percent}%
            </label>
            {addButton}
          </div>
        );
      })}
    </>
  );

  const contents = !sectionOpen ? (
    <>
      <button
        className={styles.openCreateSplitter}
        onClick={() => {
          setSectionOpen(!sectionOpen);
          if (created) {
            setAddresses(["", ""]);
            setShares([1, 1]);
            setCreated(false);
          }
        }}
      >
        Create Splitter
      </button>
    </>
  ) : (
    <div className={styles.createSplitterForm}>
      <h3> Create Splitter </h3>
      <form onSubmit={createSplitter}>
        {addressContents}
        <br />

        <button
          type="submit"
          className={styles.createSplitterSubmit}
          disabled={!signerAddress || created}
        >
          Create Payment Splitter
        </button>
        <button
          className={styles.closeButton}
          onClick={() => {
            setSectionOpen(!sectionOpen);
          }}
        >
          {created ? "Close" : "Cancel"}
        </button>
        {taxLabel}
        {created ? (
          <div className={styles.successMessage}>
            Success! You've submitted your payment splitter creation
            transaction. <br />
            The payment splitter should be processed by the ethereum network in
            the next 2 minutes. <br />
            Once Metamask notifies you that the transaction has been confirmed,
            refresh the page to see your payment splitter.
          </div>
        ) : null}
      </form>
    </div>
  );

  return <div className={styles.createSplitterSection}>{contents}</div>;
}
