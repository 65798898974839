import React from "react";
import * as styles from "../App.module.scss";

export default function Footer(props) {
  return (
    <div className={styles.footer}>
      <div>
        <span className={styles.proudly}>
          Proudly Presented by{" "}
          <a href="https://www.northwestnfts.com/">NorthwestNFTs</a>
        </span>
      </div>

      <div>
        Without accounts or emails, it's hard to talk to our users. We'd love to
        hear from you!
        <br />
        Send your feedback to{" "}
        <a href="mailto:paymentsplitter@northwestnfts.com">
          paymentsplitter@northwestnfts.com
        </a>
      </div>
    </div>
  );
}
