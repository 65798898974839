import { get, has } from "lodash";

const CloneablePromise = import("../contracts/PaymentSplitterCloneable.json");

const importPathsByNetwork = {
  localhost: import("../contracts/debug_contracts.json"),
  ropsten: import("../contracts/ropsten_contracts.json"),
  mainnet: import("../contracts/mainnet_contracts.json"),
  matic: import("../contracts/polygon_contracts.json"),
};

// @ts-ignore
// const externalContractsPromise = import("../contracts/external_contracts");

export const loadAppContracts = async () => {
  const contractListPromise = get(
    importPathsByNetwork,
    process.env.ETH_NETWORK_NAME, // eslint-disable-line
    importPathsByNetwork["localhost"]
  );
  // console.log("what import path", importPath);
  // const contractListPromise = import(importPath);
  const config = {};
  config.deployedContracts = (await contractListPromise).default ?? {};

  for (let key in config.deployedContracts) {
    for (let jkey in config.deployedContracts[key]) {
      if (has(config.deployedContracts[key][jkey], "contracts", false)) {
        config.deployedContracts[key][jkey]["contracts"][
          "PaymentSplitterCloneable"
        ] = (await CloneablePromise) ?? {};
      }
    }
  }

  // config.deployedContracts["PaymentSplitterCloneable"] =
  // (await CloneablePromise) ?? {};
  // config.externalContracts = (await externalContractsPromise).default ?? {};
  return config;
};
