import React, { useState } from "react";
import { keys, map } from "lodash";
import * as styles from "../App.module.scss";
import * as cn from "classnames";

const networkUrls = {
  mainnet: "https://paymentsplitter.io",
  ropsten: "https://ropsten.paymentsplitter.io",
  rinkeby: "https://rinkeby.paymentsplitter.io",
  matic: "https://polygon.paymentsplitter.io",
};

export default function NetworkSelector(props) {
  const [opened, setOpened] = useState(false);
  // grab environment
  // grab list of environments

  const contents = map(keys(networkUrls), (k) => {
    return (
      <a key={k} className={styles.networkUrl} href={networkUrls[k]}>
        {k}
      </a>
    );
  });

  return (
    <div className={styles.networkSelector} onClick={() => setOpened(!opened)}>
      Network:
      <span className={styles.currentNetwork}>
        {process.env.ETH_NETWORK_NAME}
      </span>
      <div
        className={cn(styles.networkSelectorDropdown, {
          [styles.showDropdown]: opened,
        })}
      >
        {contents}
      </div>
    </div>
  );
}
